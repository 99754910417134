var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"chat-container"},[_c('div',{staticClass:"chat-header pb-3"},[_vm._v("Student Inquiries Chat")]),_c('div',{ref:"chatMessagesContainer",attrs:{"id":"chat-messages-container"},on:{"scroll":_vm.onScroll}},[_c('div',{staticClass:"chat-messages",class:{ 'messages-available': _vm.areChatsAvailable }},[(!_vm.areChatsAvailable)?_c('div',[(!_vm.areChatsAvailable)?_c('div',{staticClass:"start-chat-container"},[_c('el-empty',{attrs:{"description":" Type a message to get started ..."}})],1):_vm._e()]):_vm._e(),(_vm.newChatMessage)?_c('div',{staticClass:"new-message-indicator clickable",on:{"click":_vm.showNewMessage}},[_c('span',{staticClass:"new-message-indicator-text"},[_c('i',{staticClass:"el-icon-bottom new-message-indicator-text"}),_vm._v(" "+_vm._s(_vm.newMessageCount)+" New message(s) ")])]):_vm._e(),_vm._l((_vm.messages),function(message,index){return _c('div',{key:index,staticClass:"chat-message",class:[
          _vm.checkIfIsOwner(message) ? 'sender-position' : 'receiver-position',
        ],on:{"mouseenter":function($event){return _vm.handleDeleteOnHover(index)},"mouseleave":function($event){return _vm.removeDeleteButton(index)}}},[_c('div',{ref:"chatMessageContent",refInFor:true,staticClass:"chat-message-content",class:[
            message.hasFailed && !_vm.failedToDeleteMessage ? 'clickable' : '',
          ],on:{"click":function($event){return _vm.resendFailedMessage(message.private_client_chat_id)}}},[_c('div',{staticClass:"chat-message-content-text",class:[
              _vm.checkIfIsOwner(message)
                ? 'sender-bg-color position-end'
                : 'receiver-bg-color position-start',
              _vm.isDeleting.loading && _vm.isDeleting.id == message.chat_message_id
                ? 'deleting-color'
                : '',
            ]},[(!_vm.checkIfIsOwner(message))?_c('span',{staticClass:"sender-first-name"},[_vm._v(_vm._s(message.sender && message.sender.first_name)+" "+_vm._s(message.sender && message.sender.last_name))]):_vm._e(),_c('span',[_vm._v(" "+_vm._s(message.message)+" "),(message.hasFailed)?_c('i',{staticClass:"el-icon-warning icon_container error-color"}):_vm._e()])]),_c('div',{staticClass:"chat-message-content-date",class:[
              _vm.checkIfIsOwner(message) ? 'position-end' : 'position-start',
            ]},[(
                !message.chat_message_id ||
                (_vm.isDeleting.loading &&
                  _vm.isDeleting.id == message.chat_message_id)
              )?_c('span',{staticClass:"icon_container pr-2"},[(!message.hasFailed && !_vm.failedToDeleteMessage)?_c('i',{staticClass:"el-icon-loading"}):_vm._e()]):_vm._e(),(message.hasFailed)?_c('div',{staticClass:"resend-message"},[_c('i',{staticClass:"el-icon-refresh icon_container error-color position-end"}),_c('span',[(message.showDeleteFailure)?_c('span',[_vm._v("Failed to delete")]):_vm._e(),(message.showFailedToSend)?_c('span',[_vm._v("Tap to send again")]):_vm._e()])]):_vm._e(),(
                _vm.checkIfIsOwner(message) &&
                message.chat_message_id &&
                !message.hasFailed
              )?_c('span',{staticClass:"icon_container sent-icon-color pr-2"},[_c('i',{staticClass:"el-icon-circle-check"})]):_vm._e(),(!message.hasFailed)?_c('span',{staticClass:"date-title"},[_vm._v(_vm._s(_vm.convertDateToString(message))+" ")]):_vm._e(),_c('span',{staticClass:"delete-btn",class:[
                _vm.showDeleteButtonId == index &&
                _vm.checkIfIsOwner(message) &&
                !_vm.failedToDeleteMessage
                  ? 'show'
                  : 'hidden',
              ],on:{"click":function($event){return _vm.deleteMessage(message)}}},[_c('i',{staticClass:"el-icon-delete error-color"})])])])])}),((_vm.isLoading || _vm.isScrollTopLoading) && !_vm.isDoneFetchingAllMessages)?_c('div',{staticClass:"d-flex align-items-center justify-content-center",staticStyle:{"height":"80vh"}},[_c('ScaleOut',{staticClass:"mx-auto",attrs:{"background":'#1F4EA1'}})],1):_vm._e(),(_vm.isDoneFetchingAllMessages)?_c('div',{attrs:{"id":"loaded-chats"}},[_c('span',[_vm._v("No Older Messages")])]):_vm._e()],2)]),_c('div',{attrs:{"id":"chat-input-container"}},[_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.textInput),expression:"textInput",modifiers:{"trim":true}}],ref:"chatInput",staticClass:"chat-input-text",attrs:{"type":"text","id":"chat-input","placeholder":"Type a message...","autocomplete":"off","maxlength":"500"},domProps:{"value":(_vm.textInput)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.sendMessage.apply(null, arguments)},"input":function($event){if($event.target.composing)return;_vm.textInput=$event.target.value.trim()},"blur":function($event){return _vm.$forceUpdate()}}}),_c('button',{staticClass:"send-input-button mt-0 mt-md-3",attrs:{"id":"chat-send-button"},on:{"click":_vm.sendMessage}},[_c('i',{staticClass:"fas fa-paper-plane",staticStyle:{"color":"#000","font-size":"16px"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }