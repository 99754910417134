<template>
  <div id="app">
    <AuthenticatingComponent v-if="$store.state.isAuthenticating" />
    <router-view v-else />
    <div class="staging_env_banner d-none d-lg-block" v-if="isStagingEnv">
      Staging Environment
    </div>

    <!-- <CoolLightBox
      :items="items"
      :index="index"
      @close="index = null"
      :useZoomBar="true"
      :effect="'fade'"
    >
    </CoolLightBox> -->

    <PopupDialog
      :is-visible="showPopupDialog"
      :content="popupDialogContent"
      :title="popupDialogTitle"
      @close-dialog="closePopupDialog"
    />
    <div class="app-version">{{ appVersion }}</div>
  </div>
</template>

<script>
import "firebase/firestore";
import "firebase/messaging";
import AuthenticatingComponent from "./components/authenticating.component.vue";
// import CoolLightBox from "vue-cool-lightbox";
import PopupDialog from "./components/dialogs/popup-dialog";
import "vue-cool-lightbox/dist/vue-cool-lightbox.min.css";
const isStagingEnv = process.env.VUE_APP_IS_STAGING;
const APP_VERSION = process.env.VUE_APP_VERSION;

const isDev = process.env.NODE_ENV !== "production";
export default {
  components: { /* CoolLightBox, */ PopupDialog, AuthenticatingComponent },
  data() {
    return {
      // items: [
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Direct+Bank+payments.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Fees+Payment+options.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Flexipay.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Mobile+and+Online+payments.jpg",
      //   "https://victoria-university-elearning.s3.amazonaws.com/vu_payment_images/Mobile+payments.jpg",
      // ],
      // index: null,
      installingNotification: null,
      forceReload: false,
      isStagingEnv,
      showPopupDialog: false,
      appVersion: isDev ? "Development" : `Version 3.${APP_VERSION}`,
      popupDialogTitle: "📢 Welcome Back, Students! 📢",
      popupDialogContent: `
              <div>
                  We hope you had a restful and refreshing break! As we begin the <span style="font-weight: 500">March 2025 Trimester</span>, we are excited to welcome both returning and new students to another enriching academic journey. Please take note of the important dates for the trimester below:


                    <table style="width: 100%; border-collapse: collapse; margin-top: 20px">
                          <tr>
                              <th style="border: 1px solid black; padding: 8px; background-color: #f2f2f2; text-align: left;">March Trimester</th>
                              <th style="border: 1px solid black; padding: 8px; background-color: #f2f2f2; text-align: left;">Teaching Dates</th>
                              <th style="border: 1px solid black; padding: 8px; background-color: #f2f2f2; text-align: left;">Exam Dates</th>
                          </tr>
                          <tr>
                              <td style="border: 1px solid black; padding: 8px; font-weight: 500">New Student Induction</td>
                              <td style="border: 1px solid black; padding: 8px;">19th to 21st March 2025</td>
                              <td style="border: 1px solid black; padding: 8px;"></td>
                          </tr>
                          <tr>
                              <td style="border: 1px solid black; padding: 8px; font-weight: 500">Teaching Block 1</td>
                              <td style="border: 1px solid black; padding: 8px;">24th March to 24th April 2025</td>
                              <td style="border: 1px solid black; padding: 8px;">25th and 26th April</td>
                          </tr>
                          <tr>
                              <td style="border: 1px solid black; padding: 8px; font-weight: 500;">Teaching Block 2</td>
                              <td style="border: 1px solid black; padding: 8px;">28th April to 29th May 2025</td>
                              <td style="border: 1px solid black; padding: 8px;">30th and 31st May</td>
                          </tr>
                          <tr>
                              <td style="border: 1px solid black; padding: 8px; font-weight: 500">Teaching Block 3</td>
                              <td style="border: 1px solid black; padding: 8px;">2nd June to 3rd July 2025</td>
                              <td style="border: 1px solid black; padding: 8px;">4th and 5th July</td>
                          </tr>
                          <tr>
                              <td style="border: 1px solid black; padding: 8px; font-weight: 500">Holiday</td>
                              <td style="border: 1px solid black; padding: 8px;">6th to 20th July 2025</td>
                              <td style="border: 1px solid black; padding: 8px;"></td>
                          </tr>
                      </table>

                      <div style="margin-top: 25px">
                        We encourage you to plan ahead and make the most of this trimester. Wishing you all a successful and productive academic term!

                        </div>
              
                </div>`,
    };
  },
  computed: {
    forcedUpdateLastChecked() {
      return this.$store.state.forcedUpdateLastChecked;
    },

    isAlreadyShowingCheckForUpdatesDialog() {
      return this.$store.state.isAlreadyShowingCheckForUpdatesDialog;
    },
  },

  mounted() {
    if (
      this.$route.name != "OngoingCourseWork" &&
      this.$route.name != "OngoingExamination"
    ) {
      if (!process.env.VUE_APP_NO_STUDENT_POPUP_DIALOG) {
        // Wait 3 seconds and show popup dialog
        setTimeout(() => {
          this.openPopupDialog();
        }, 3000);
      }

      // this.$nextTick().then(() => {
      //   if (process.env.NODE_ENV === "production") {
      //     this.index = 0;
      //   }
      // });
    }
  },

  watch: {
    forcedUpdateLastChecked() {
      //Instruct the app to immediately check for updates as it means there's a NEW VERSION

      this.forceReload = true;
      this.serviceWorkerCheckUpdates();
    },

    isAlreadyShowingCheckForUpdatesDialog() {
      this.$notify({
        title: "Check Updates",
        dangerouslyUseHTMLString: true,
        // iconClass: "fas fa-download",
        type: "info",
        showClose: false,
        onClick: () => {
          location.reload();
          return false;
        },
        message: `Your app may be outdated. Please click below to  check for an update
                      <button
                          type="button"
                          class="mt-2 btn z-depth-0"
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          style="
                            width: 100px;
                            text-transform: capitalize;
                            font-size: 13px;
                            height: 35px;
                            border-radius: 40px;
                            padding: 10px 10px;
                            background-color: var(--el-app-primary);
                          "
                        >
                          <span style="position: relative; bottom: 3px">Check</span>
                        </button>
                      `,
        duration: 0,
      });
    },
  },

  methods: {
    serviceWorkerCheckUpdates() {
      navigator.serviceWorker.getRegistrations().then(function (registrations) {
        let serviceWorker = registrations.find(
          (worker) =>
            worker.active &&
            worker.active.scriptURL.includes("service-worker.js")
        );
        //If we found it, attempt to fetch updates
        if (serviceWorker) {
          serviceWorker.update();
        }
      });
    },
    openPopupDialog() {
      this.showPopupDialog = true;
    },
    closePopupDialog() {
      this.showPopupDialog = false;

      // setTimeout(() => {
      //   if (
      //     this.popupDialogTitle ===
      //     "📢 IMPORTANT DATES FOR MARCH TRIMESTER 2024 📢"
      //   ) {
      //     this.popupDialogTitle = "📢 COLLECTION OF IDENTITY CARDS 📢";
      //     this.popupDialogContent = `

      //         <div>
      //             <div class="mt-2">
      //               Dear students,
      //             </div>
      //             <div class="mt-1">
      //               We are pleased to inform you that the identity cards for students of March 2023, July 2023, November 2023, and March 2024 intakes are now available for collection. If you have already provided your passport photos, please visit the Admissions Office at your earliest convenience to collect your ID card.
      //             </div>
      //             <div class="mt-2">
      //               For those in the March 2024 intake who have not yet submitted their passport photos, it is essential to do so promptly to avoid any delays in receiving your identity card. Kindly come to the Admissions Office to have your photo taken and complete this requirement.
      //             </div>
      //             <div class="mt-2">
      //               Thank you.
      //             </div>

      //           </div>`;

      //     this.showPopupDialog = true;
      //   }
      // }, 1000);
    },
  },

  created() {
    document.addEventListener(
      "visibilitychange",
      function () {
        document.dispatchEvent(
          new CustomEvent("appVisibilityChange", { detail: document.hidden })
        );
      },
      false
    );

    navigator.serviceWorker.addEventListener("message", (event) => {
      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Check Update") ||
        (event.data.data && event.data.data.title == "Check Update")
      ) {
        this.serviceWorkerCheckUpdates();
      }

      //If it is an update request, then update the app
      if (
        (event.data && event.data.title == "Fetch Notifications") ||
        (event.data.data && event.data.data.title == "Fetch Notifications")
      ) {
        this.$store.commit("UPDATE_NOTIFICATIONS");
      }
    });

    document.addEventListener(
      "swUpdateFound",
      () => {
        this.installingNotification = this.$notify({
          title: "We are Updating",
          // type: "info",
          iconClass: "el-icon-loading mt-2",
          showClose: false,
          message: "Fetching new update now...",
        });
      },
      { once: true }
    );

    document.addEventListener(
      "swUpdated",
      () => {
        if (this.installingNotification) {
          this.installingNotification.close();
        }

        if (this.forceReload) {
          location.reload();
          return false;
        }

        this.$notify({
          title: "Update Available",
          dangerouslyUseHTMLString: true,
          // iconClass: "fas fa-download",
          type: "info",
          showClose: false,
          onClick: () => {
            location.reload();
            return false;
          },
          message: `There is an update available. Please Click below to refresh
                      <button
                          type="button"
                          class="mt-2 btn z-depth-0"
                          v-ripple="'rgba(255, 255, 255, 0.35)'"
                          style="
                            width: 100px;
                            text-transform: capitalize;
                            font-size: 13px;
                            height: 35px;
                            border-radius: 40px;
                            padding: 10px 10px;
                            background-color: var(--el-app-primary);
                          "
                        >
                          <span style="position: relative; bottom: 3px">Update</span>
                        </button>
                      `,
          duration: 0,
        });
      },
      { once: true }
    );
  },

  destroyed() {},
};
</script>

<style scoped>
b {
  font-weight: 600 !important;
}
</style>

<style>
* {
  word-break: normal;
  overflow-wrap: break-word; /* Allows breaking only at appropriate points, like spaces */
  white-space: normal; /* Ensures proper spacing and wrapping */
}

/* Global override to remove the max-width as it was used on all pages and causes large paddings on the sides and a global replace on all pages
was very expensive to do */
.container {
  max-width: none !important;
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.staging_env_banner {
  background-color: var(--vu-red) !important;
  color: white;
  padding: 5px 10px;
  z-index: 2000;
  font-size: 0.9em;
  border-radius: 5px;
  position: fixed;
  bottom: 10px;
  right: 10px;
}

.cursor-pointer {
  cursor: pointer !important;
}

input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

.el-message-box__title {
  font-size: 17px;
  font-weight: 600;
  font-family: "Euclid Circular B";
}

.el-popper {
  font-family: "Euclid Circular B" !important;
}

.el-button--primary {
  color: #fff;
  background-color: var(--el-app-primary) !important;
  border-color: var(--el-app-primary) !important;
}

.el-message-box__content {
  color: #4d4e52;
  font-weight: 400;
  font-size: 13px;
  font-family: "Euclid Circular B";
}

.el-notification__content {
  text-align: left !important;
}

.el-notification.right {
  font-family: "Euclid Circular B" !important;
  z-index: 9999 !important;
}

.bread-crumb-item {
  font-size: 0.8em;
  font-weight: 500;
  color: #919191;
  cursor: pointer;
}

.bread-crumb-item:hover,
.bread-crumb-home:hover {
  color: #000 !important;
  transition: 1s;
}

#app {
  font-family: "Euclid Circular B";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

input.legacy-input {
  height: calc(1.55rem) !important;
  padding: 5px;
  font-size: 0.8rem !important;
  font-weight: 400 !important;
  color: #495057 !important;
  background-color: #fff !important;
  background-clip: padding-box !important;
  border: 1px solid #ced4da !important;
  border-radius: 0.25rem !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out !important;
}

input.legacy-input:disabled {
  background-color: rgb(248, 246, 246) !important;
}

input.legacy-input.input-error {
  border: 1px solid red !important;
  box-shadow: none !important;
  /* Start the shake animation and make the animation last for 0.5 seconds */
  animation: shake 0.5s;
  /*  animation-iteration-count:  infinite;  */
}

input.legacy-input:focus:not(.input-error) {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #80bdff !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.1rem rgba(0, 123, 255, 0.25) !important;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f6f6f6;
}

/* Handle */
::-webkit-scrollbar-thumb {
  width: 10px;
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  width: 10px;
  background: #042f6c;
  border-radius: 10px;
}

.card {
  box-shadow: 0 2px 5px 2px rgb(0 0 0 / 0%), 0 2px 10px 0 rgb(0 0 0 / 5%) !important;
}

button:disabled {
  cursor: not-allowed !important;
  pointer-events: all !important;
}

.el-button:disabled:not(.el-button--success, .el-button--warning) {
  opacity: 0.65 !important;
}

.el-button--success.is-disabled {
  color: #fff !important;
  background-color: #67c23a !important;
  border-color: #67c23a !important;
}

.el-button--warning.is-disabled {
  color: #fff !important;
  background-color: #e6a23c !important;
  border-color: #e6a23c !important;
}

.app-version {
  position: fixed;
  bottom: 0px;
  right: 0px;
  font-size: 0.65em;
  color: rgba(0, 0, 0, 0.473);
  padding: 2px 8px;
  background-color: white;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
}
</style>
