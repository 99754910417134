import AwsUploadUrl from "@/interfaces/awsUploadUrl.interface";
import axios from "axios";
import { useShowWarningMessage } from "./use-notifications";

export const useUploadResourceToAWS = async ({
  awsUploadUrl,
  selectedFile,
  onProgress,
}: {
  awsUploadUrl: AwsUploadUrl;
  selectedFile: File | Blob;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onProgress: (progressEvent: ProgressEvent) => void;
}): Promise<string | undefined> => {
  try {
    const formData = new FormData();
    Object.entries(awsUploadUrl.fields).forEach(([key, value]) => {
      //@ts-ignore
      formData.append(key, value);
    });
    formData.append("file", selectedFile);

    // send the file to the aws server
    const response = await axios.post(`${awsUploadUrl.url}`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: " ",
      },
      onUploadProgress: onProgress,
    });

    if (response.status == 204) {
      return response.headers.location;
    } else {
      throw "UNEXPECTED_RESPONSE";
    }
  } catch (error) {
    if ((error as any).message == "Network Error") {
      useShowWarningMessage(
        "Connection failed",
        "A network error occurred, please try again."
      );
    }
    useShowWarningMessage(
      "Upload Failed",
      "Unable to Upload file Now, please try again"
    );
  }
};
