var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('section',[_c('div',{staticStyle:{"background-color":"white"},attrs:{"id":"sidebar-article"}},[_c('div',{staticStyle:{"cursor":"pointer"}},[_c('div',{staticStyle:{"margin-bottom":"10px","display":"flex","align-items":"center","justify-content":"center"}},[_c('div',[_c('img',{staticClass:"mt-3 img-fluid",staticStyle:{"height":"65px"},attrs:{"src":_vm.$store.state.logoImageUrl,"alt":""}})])])]),_c('div',{staticStyle:{"margin-top":"50px"}},[_c('ul',{},[_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/home"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Home' }},[_c('span',[_c(_setup.HomeIcon,{staticClass:"svg-icon"}),_vm._v(" Home")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/my-course-units"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'MyCourseUnits' ||
                  _vm.$route.name == 'CourseUnitResources' ||
                  _vm.$route.name == 'ResourceDetails',
              }},[_c('span',[_c(_setup.FoldersIcon,{staticClass:"svg-icon"}),_vm._v(" My Modules")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/lectures"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Lectures' }},[_c('span',[_c(_setup.VideoIcon,{staticClass:"svg-icon"}),_vm._v(" Lectures")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/my-calendar"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'MyCalendar' }},[_c('span',[_c(_setup.ClockIcon,{staticClass:"svg-icon"}),_vm._v(" My Timetable")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/course-works"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'Courseworks' ||
                  _vm.$route.name == 'CourseworksAll',
              }},[_c('span',[_c(_setup.BookOpenIcon,{staticClass:"svg-icon"}),_vm._v(" Course Work")],1)])])],1),_c('li',{class:_vm.$store.getters.canViewExaminations ? 'show_list' : 'hide_list',on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/examinations"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'Examinations' ||
                  _vm.$route.name == 'ExamSubmissions',
              }},[_c('span',[_c(_setup.HourglassIcon,{staticClass:"svg-icon"}),_vm._v(" Examinations")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/student-results"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'StudentResults' }},[_c('span',[_c(_setup.GraduationCapIcon,{staticClass:"svg-icon"}),_vm._v(" Examination Results")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/transactions"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Transactions' }},[_c('span',[_c(_setup.CoinsIcon,{staticClass:"svg-icon"}),_vm._v(" Financial Statements")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/tutorials"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'Tutorials' ||
                  _vm.$route.name == 'TutorialDetails',
              }},[_c('span',[_c(_setup.TelevisionIcon,{staticClass:"svg-icon"}),_vm._v(" Tutorials")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/elections/intro"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'Elections' || _vm.$route.name == 'Elections',
              }},[_c('span',[_c(_setup.CheckboxIcon,{staticClass:"svg-icon"}),_vm._v(" VU Elections")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/inquiries"}},[_c('div',{staticClass:"side-link",class:{
                active:
                  _vm.$route.name == 'inquiries' || _vm.$route.name == 'inquiries',
              }},[_c('span',[_c(_setup.MessageIcon,{staticClass:"svg-icon"}),_vm._v(" Chats / Inquiries")],1)])])],1),_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/apply-for-changes"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'ApplyForChanges' }},[_c('span',[_c(_setup.EditIcon,{staticClass:"svg-icon"}),_vm._v(" Apply For Changes")],1)])])],1)])]),_c('div',{staticStyle:{"margin-top":"30px"}},[_c('ul',[_c('li',{on:{"click":function($event){return _setup.closeNav()}}},[_c('router-link',{attrs:{"to":"/dashboard/settings"}},[_c('div',{staticClass:"side-link",class:{ active: _vm.$route.name == 'Settings' }},[_c('span',[_c(_setup.SettingsIcon,{staticClass:"svg-icon"}),_vm._v(" Settings")],1)])])],1),_c('li',{on:{"click":_setup.onLogoutIntent}},[_c('div',{staticClass:"side-link"},[_c('span',[_c(_setup.LogoutIcon,{staticClass:"svg-icon"}),_vm._v(" Logout")],1)])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }